import React, { useEffect, useState } from "react";
import DesktopNav from "../nav/DesktopNav";
import Hidden from "@mui/material/Hidden";
import MobileNav from "../nav/MobileNav";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Box from "@mui/material/Box";
import FavoriteIcon from "@mui/icons-material/Favorite";
import theme from "../../theme";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { FaSpotify } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import NotesIcon from "@mui/icons-material/Notes";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import TextField from "@mui/material/TextField";
import StopIcon from "@mui/icons-material/Stop";
import { playButtonClick } from "../../redux/actions/dataActions";

const authorized = ["claireknill", "kyle", "yambo", "p"];
function Special({ user, ui, playButtonClick }) {
  let own = "kyle" === user.data.handle;
  const [playlist, setPlaylist] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openNotes, setOpenNotes] = useState([]);
  const [addingNotes, setAddingNotes] = useState([]);
  const [newNotes, setNewNotes] = useState({});
  const [disableNotes, setDisableNotes] = useState(false);
  const [addNotesLoading, setAddNotesLoading] = useState(false);
  const noteChange = (e, v) => {
    setNewNotes({
      ...newNotes,
      [e.target.name]: e.target.value,
    });
  };
  const [likedSongs, setLikedSongs] = useState([]);
  const [successSnack, setSuccessSnack] = useState(false);
  const favoriteSong = (id) => {
    setLikedSongs([...likedSongs, id]);
    axios
      .post("/likesong/spotify", { ids: [id] })
      .then((res) => {
        setSuccessSnack("Song Added to Spotify Likes");
      })
      .catch((err) => {
        alert("could not add this song to your likes srry bruv");
      });
  };
  const addNote = () => {
    setAddNotesLoading(true);
    const newNotesIndices = Object.keys(newNotes);
    const newNoteArray = [];
    playlist.songs.forEach((song, ind) => {
      if (newNotesIndices.includes(ind.toString())) {
        newNoteArray.push(newNotes[ind]);
      } else {
        const note = playlist.notes[ind] || null;
        newNoteArray.push(note);
      }
    });
    const body = {
      notes: newNoteArray,
    };
    axios
      .post(`/editplaylist/Wq57bJtY7QRL26BQLPba`, body)
      .then((res) => {
        setPlaylist({
          ...playlist,
          notes: newNoteArray,
        });
        setNewNotes({});
        setAddingNotes([]);
        setOpenNotes([]);
        setAddNotesLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAddNotesLoading(false);
        alert("error adding notes sorry bruv");
      });
  };
  const Loading = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress style={{ color: "#fff" }} />
      </div>
    );
  };
  const [noAccess, setNoAccess] = useState(true);
  useEffect(() => {
    setLoading(true);
    // if (Object.keys(user.data) > 1) {
    axios
      .get("/playlist/Wq57bJtY7QRL26BQLPba")
      .then((res) => {
        if (authorized.includes(user.data.handle)) {
          setNoAccess(false);
          setPlaylist(res.data);
          setLoading(false);
          if (user.data.handle !== "kyle") {
            axios.get("/analytics/pageview");
          }
        } else if (!user.authenticated) {
        } else {
          setNoAccess(true);
          setLoading(false);
          axios.get("/analytics/pageview");
          alert("this account doesn't have access to this, sorry :(");
        }
      })
      .catch((err) => {
        alert("could not get playlist sorry!");
      });
    // }
  }, [user.data]);
  const history = useHistory();
  return (
    <div>
      <Hidden mdDown>
        <DesktopNav />
      </Hidden>
      <Hidden mdUp>
        <MobileNav />
      </Hidden>
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
          {noAccess && <Typography align="center">no access!!!</Typography>}
          {!noAccess && (
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  marginTop: "2.5rem",
                  marginBottom: "1rem",
                  [theme.breakpoints.down("lg")]: {
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  },
                }}
              >
                <div style={{ margin: "0 .1rem" }}>
                  {/* {getPlaylistCover(playlist, 6)} */}
                  <Box
                    component="img"
                    src={
                      playlist.images.length > 0
                        ? playlist.images[0].url
                        : "https://firebasestorage.googleapis.com/v0/b/flumes-company.appspot.com/o/907e87639091f8805c48681d9e7f144dedf53741.jpg?alt=media&token=614a343b-997a-49e2-973f-31bae278f6fc"
                    }
                    sx={{
                      objectFit: "cover",
                      objectPosition: "center center",
                      height: "12rem",
                      width: "12rem",
                    }}
                  />
                </div>
                <div style={{ marginLeft: "1rem" }}>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    sx={{
                      [theme.breakpoints.down("lg")]: {
                        margin: "1rem 0",
                      },
                    }}
                  >
                    {playlist.title}
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: ".4rem",
                    }}
                    variant="body1"
                    color="textSecondary"
                  >
                    {playlist.description}
                  </Typography>
                </div>
              </Box>
              <div
                style={{
                  justifyContent: "center",
                  textTransform: "uppercase",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle2" color="textSecondary">
                  By{" "}
                  <Box
                    component="span"
                    sx={{
                      "&:hover": {
                        textDecoration: "underline",
                        color: theme.palette.text.primary,
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => history.push(`/${playlist.user}`)}
                  >
                    @{playlist.user}
                  </Box>
                </Typography>
                &nbsp;
                <span
                  style={{
                    fontSize: "5px",
                    color: theme.palette.text.secondary,
                  }}
                >
                  {"\u2B24"}
                </span>
                &nbsp;
                <Typography variant="subtitle2" color="textSecondary">
                  {playlist.songs.length}&nbsp;songs
                </Typography>
              </div>
              {playlist.status === "spotify" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    sx={{
                      borderColor: theme.palette.primary.light,
                      textTransform: "capitalize",
                      marginRight: "1rem",
                      color: theme.palette.text.primary,
                      "&:hover": {
                        borderColor: theme.palette.text.primary,
                      },
                    }}
                    startIcon={<FaSpotify />}
                    variant="outlined"
                    onClick={() => {
                      window.open(
                        `https://open.spotify.com/playlist/${playlist.spotifyId}`
                      );
                      axios.get("/analytics/openedinspotify");
                    }}
                  >
                    Open in Spotify
                  </Button>
                </div>
              )}
              <Box
                sx={{
                  width: "70%",
                  margin: "0 auto",
                  padding: "1rem 0",
                  [theme.breakpoints.down("lg")]: {
                    width: "80%",
                  },
                  [theme.breakpoints.down("md")]: {
                    width: "90%",
                  },
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
              >
                {playlist.songs.map((song, index) => {
                  return (
                    <div key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: ".15rem 1rem",
                          "&:hover": {
                            background: theme.palette.primary.light,
                            cursor: "pointer",
                          },
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: ".5rem",
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            {index + 1}
                          </Typography>
                          <img
                            src={song.images[0].url}
                            alt={song.name}
                            style={{
                              height: "3.75rem",
                              width: "3.75rem",
                              margin: "0 1rem",
                              borderRadius: "4px",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography variant="body2">{song.name}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {song.artists.join(", ")}
                            </Typography>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          {playlist.notes[index] && (
                            <IconButton
                              sx={{ color: theme.palette.text.primary, flexDirection: "column" }}
                              size="small"
                              onClick={() => {
                                if (!openNotes.includes(index)) {
                                  setOpenNotes([...openNotes, index]);
                                  if (user.data.handle !== "kyle") {
                                    axios.get(`/analytics/opennote${index}`);
                                  }
                                } else {
                                  setOpenNotes(
                                    openNotes.filter((item) => item !== index)
                                  );
                                }
                              }}
                            >
                              <NotesIcon />
                              <Typography sx={{
                                  fontSize: "11px"
                              }}>see note</Typography>
                            </IconButton>
                          )}
                          {!playlist.notes[index] && own && !disableNotes && (
                            <IconButton
                              sx={{ color: theme.palette.text.primary }}
                              size="small"
                              onClick={() => {
                                if (!addingNotes.includes(index)) {
                                  setAddingNotes([...addingNotes, index]);
                                } else {
                                  setAddingNotes(
                                    addingNotes.filter((item) => item !== index)
                                  );
                                }
                              }}
                            >
                              <NoteAddIcon />
                            </IconButton>
                          )}
                          <div style={{ display: "flex" }}>
                            {!own && (
                              <IconButton
                                sx={{
                                  color: theme.palette.text.primary,
                                }}
                                size="small"
                                onClick={() => {
                                  favoriteSong(song.spotifyId);
                                }}
                                name={song.spotifyId}
                              >
                                {(user.data.likedSongs &&
                                  user.data.likedSongs.includes(
                                    song.spotifyId
                                  )) ||
                                likedSongs.includes(song.spotifyId) ? (
                                  <FavoriteIcon style={{ color: "white" }} />
                                ) : (
                                  <FavoriteBorderIcon
                                    style={{ color: "white" }}
                                  />
                                )}
                              </IconButton>
                            )}
                            {ui.audio.active &&
                            ui.audio.src === song.preview ? (
                              <IconButton
                                onClick={() => {
                                  playButtonClick(song.preview, ui.audio);
                                }}
                                sx={{ color: theme.palette.text.primary }}
                                size="small"
                              >
                                <StopIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => {
                                  playButtonClick(song.preview, ui.audio);
                                }}
                                sx={{ color: theme.palette.text.primary }}
                                size="small"
                              >
                                <PlayArrowIcon />
                              </IconButton>
                            )}
                          </div>
                        </div>
                      </Box>
                      {openNotes.includes(index) && !own && (
                        <div style={{ width: "90%", margin: ".3rem auto", display: "flex", alignItems: "center" }}>
                            <IconButton
                                  sx={{
                                    color: theme.palette.text.primary,
                                    marginRight: ".5rem",
                                  }}
                                  size="small"
                                  onClick={() => {
                                    setOpenNotes(
                                      openNotes.filter(
                                        (item) => item !== index
                                      )
                                    );
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                          <Typography variant="body2" color="textPrimary">
                            {playlist.notes[index]}
                          </Typography>
                        </div>
                      )}
                      {openNotes.includes(index) && own && (
                        <div
                          style={{
                            width: "90%",
                            margin: ".3rem auto",
                            display: "flex",
                            alignItems: "flex-end",
                            marginBottom: ".8rem",
                          }}
                        >
                          <TextField
                            sx={{
                              "& .MuiInputBase-root": {
                                borderBottom: "1px solid white",
                              },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: theme.palette.text.primary,
                              },
                            }}
                            label="Add a Note"
                            variant="standard"
                            fullWidth
                            name={index.toString()}
                            onChange={noteChange}
                            value={newNotes[index] || playlist.notes[index]}
                          />
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: theme.palette.text.primary,
                              color: theme.palette.primary.dark,
                              marginLeft: "1rem",
                            }}
                            size="small"
                            onClick={addNote}
                            // disabled={addNotesLoading}
                          >
                            {addNotesLoading ? "loading..." : "save"}
                          </Button>
                        </div>
                      )}
                      {addingNotes.includes(index) && (
                        <div
                          style={{
                            width: "90%",
                            margin: ".3rem auto",
                            display: "flex",
                            alignItems: "flex-end",
                            marginBottom: ".8rem",
                          }}
                        >
                          <IconButton
                            sx={{
                              color: theme.palette.text.primary,
                              marginRight: ".5rem",
                            }}
                            size="small"
                            onClick={() => {
                              setAddingNotes(
                                addingNotes.filter((item) => item !== index)
                              );
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          <TextField
                            sx={{
                              "& .MuiInputBase-root": {
                                borderBottom: "1px solid white",
                              },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: theme.palette.text.primary,
                              },
                            }}
                            label="Add a Note"
                            variant="standard"
                            fullWidth
                            name={index.toString()}
                            onChange={noteChange}
                            value={newNotes[index] || ""}
                          />
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: theme.palette.text.primary,
                              color: theme.palette.primary.dark,
                              marginLeft: "1rem",
                            }}
                            size="small"
                            onClick={addNote}
                            // disabled={addNotesLoading}
                          >
                            {addNotesLoading ? "loading..." : "save"}
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </Box>
            </div>
          )}

          {!noAccess && (
            <div>
              <Typography
                sx={{ margin: "1rem 0" }}
                color="textPrimary"
                align="center"
              >
                The first ever yamber playlist with notes!
              </Typography>
              <Typography
                sx={{ margin: "1rem 0" }}
                color="textPrimary"
                align="center"
              >
                You are merveilleuse !!! 
              </Typography>{" "}
              <Typography
                sx={{ margin: "1rem 0", paddingBottom: "3rem" }}
                color="textPrimary"
                align="center"
              >
                - Kyle
              </Typography>{" "}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

const mapState = (state) => {
  return {
    user: state.user,
    ui: state.ui,
  };
};

const mapDispatch = {
  playButtonClick,
};

export default connect(mapState, mapDispatch)(Special);
